import React from 'react'
import ContentConverter from '@/components/ContentConverter'
import Layout from '@/components/layouts/Layout'
import { withMetaContent } from '@/helpers/withMetaContent'
import { callJsonContentApi } from '@/apis/jsonData'
import { ApiPath } from '@/constants/Path'
import useContentPathSegments from '@/hooks/useContentPathSegments'

function ContentItem() {
  const { pathSegments } = useContentPathSegments()

  return (
    <>
      <Layout shrink>
        <ContentConverter pathSegments={pathSegments} />
      </Layout>
    </>
  )
}

export default ContentItem

export const getServerSideProps = withMetaContent(async (context) => {
  try {
    const contentId = context.query?.contents?.[0]

    // 로컬 환경에서 무한 재귀 호출 방지
    if (contentId === 'dist') {
      throw new Error()
    }
    const result = await callJsonContentApi({ contentId })

    return {
      props: {
        fallback: {
          [ApiPath.CONTENT(contentId)]: result.data,
        },
      },
    }
  } catch (e) {
    return {
      notFound: true,
    }
  }
})
